import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLinkCard03,
  LPickup,
  AssetImage,
  CHeroImg,
  CDefinition,
  CBtnList,
  CAccordionCard,
  CPostCard,
  CSliderMedia,
  CGeneralMedia,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import menuChoice from '../../../utils/menu-choice';
import menuGet from '../../../utils/menu-get';
import pickupChoice from '../../../utils/pickup-choice';
import pickupGet from '../../../utils/pickup-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('chefsterrace'));
  const moreData = pickupChoice(pickupGet(), ['MORE'], 0);
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          logo: {
            src: '/assets/images/restaurants/chefsterrace/logo.svg',
            alt: 'シェフズ　テラス',
          },
          title: {
            ja: 'シェフズ　テラス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/chefsterrace/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/chefsterrace/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'シェフズテラス',
            },
          }}
        />
      </CJumbotron>

      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              ガーデンを眺めながら食をたのしむ
              <br />
              緑が心地よいオールデイダイニング
            </h2>
            <p className="c_sectLead">
              気軽にシェフの珠玉の料理を味わえるリゾートレストラン。
              <br />
              四季折々の美しい景色の中で旬の味覚を贅沢に存分におたのしみいただけます。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/chefsterrace/img_hero.png',
                alt: '',
              }}
            />
          </LWrap>

          {/* LIMITED MENU - 期間限定メニュー */}
          {/* <LWrap size="small" exClass="u_mtExLarge">
            <CSectTitle
              title={{
                ja: <>期間限定メニュー</>,
                en: <>LIMITED MENU</>,
              }}
            /> 
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/chefsterrace/img_chefsterrace_limited.png',
                alt: '',
              }}
              imgSp={{
                src: '/assets/images/restaurants/chefsterrace/img_chefsterrace_limited__sp.png',
                alt: '',
              }}
            />
            <h3 className="c_headingLv3 u_tac">
              イルミネーションフレンチディナー
            </h3>
            <p className="c_sectLead">
              ビーツのソースが鮮やかな前菜をはじめ、濃厚な里芋のマッシュポテトとチャウダーで味わう白身魚のポワレなど、
              <br className="u_pc" />
              ライトアップされたガーデンを眺めながら、シェフが腕によりをかけたフレンチコースをお楽しみいただけます。
              <br className="u_pc" />
              国産牛をシンプルにグリルしたメインはフレンチ王道のソースで堪能ください。
            </p>
            <CBtnList
              data={[
                {
                  label: 'メニューはこちら',
                  link: {
                    href: '/assets/files/pdf/che_limited_menu.pdf',
                    blank: false,
                  },
                  color: 'borderTheme',
                  icon: 'blank',
                },
              ]}
            />
          </LWrap> */}
          {/* LIMITED MENU - 期間限定メニュー */}
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSliderMedia
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace02.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace03.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace04.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      リゾート環境を満喫
                      <br />
                      豊かな自然に癒される心地よい時間
                    </>
                  ),
                  text: (
                    <>
                      気持ちいいオープンエアは疲れた心と身体をリフレッシュ。
                      <br className="u_pc" />
                      美しいガーデンを眺めながら食べる料理は格別。
                      <br className="u_pc" />
                      開放感溢れる環境で友人やご家族と一緒に、
                      <br className="u_pc" />
                      ゆっくり過ごす贅沢な時間をおたのしみください。
                    </>
                  ),
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace05.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace06.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace07.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace08.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace09.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      季節の味を満喫
                      <br />
                      東北・宮城の旬を感じるメニュー
                    </>
                  ),
                  text: (
                    <>
                      東北・宮城を中心に、
                      <br className="u_pc" />
                      その時季の美味しい食材を使ったメニューをお届け。
                      <br className="u_pc" />
                      季節感溢れる料理を堪能しながら、
                      <br className="u_pc" />
                      豊かな食文化をおたのしみください。
                    </>
                  ),
                },
              ]}
            />
            {/* <CBtnList
              data={[
                {
                  label: 'ランチ',
                  link: {
                    href: '/restaurants/chefsterrace/lunch/',
                  },
                  color: 'borderTheme',
                },
              ]}
            /> */}
            <CSliderMedia
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace10.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace11.png',
                        alt: '',
                      },
                    },
                    {
                      img: {
                        src: '/assets/images/restaurants/chefsterrace/img_chefsterrace12.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      仙台リゾートの夕暮れどき
                      <br />
                      シェフと自然のもてなしを
                      <br />
                      存分に味わう時間のはじまり
                    </>
                  ),
                  text: (
                    <>
                      明かりに灯されたガーデンとシェフのもてなしが、
                      <br className="u_pc" />
                      ディナータイムにより一層のきらめきを。
                      <br />
                      バースデーなどいろいろなアニバーサリーをはじめ、
                      <br className="u_pc" />
                      大切な方との時間をお過ごしください。
                    </>
                  ),
                },
              ]}
            />
            {/* <CBtnList
              data={[
                {
                  label: 'ディナー',
                  link: {
                    href: '/restaurants/chefsterrace/dinner/',
                  },
                  color: 'borderTheme',
                },
              ]}
            /> */}
          </LWrap>
        </section>

        <section className="l_sect u_bgGray">
          <LWrap>
            <CSectTitle
              title={{
                ja: <>メニュー</>,
                en: <>MENU</>,
              }}
            />
            <CLinkCard03
              data={menuChoice(menuGet(), ['シェフズテラス 店舗 トップ'], 30)}
            />
            <CBtnList
              exClass="u_mbExLarge"
              data={[
                {
                  label: 'ご予約',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/search/e014024501/12251',
                    blank: true,
                  },
                  icon: 'blank',
                  color: 'bgGreen',
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect">
          <LWrap>
            <CSectTitle
              title={{ ja: <>営業時間｜ご利用案内</>, en: <>GUIDE</> }}
            />
            <CDefinition
              exClass="u_mb100 u_mb80_sp"
              data={shopdata.information.map((item: any) => {
                return {
                  title:
                    item.keyname &&
                    item.keyname.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    )),
                  text: (
                    <div
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{
                        __html: `${item.value}`,
                      }}
                    />
                  ),
                };
              })}
            />
          </LWrap>
        </section>
      </div>

      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>ホテル自慢の味を「もっと、たのしむ」</>,
              en: <>MORE</>,
            }}
          />
          <CPostCard col={3} data={moreData} />
        </LWrap>
      </section>
      <section className="l_sect l_chefsterrace">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>シェフズ テラスの「特別な限定席」</>,
              en: (
                <>
                  LIMITED <br className="u_sp" />
                  SEATS
                </>
              ),
            }}
          />
          <h2 className="c_headingLv2">
            ガーデンを眺めながら食をたのしむ
            <br />
            緑が心地よいオールデイダイニング
          </h2>
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/img_limited.png',
                  alt: '',
                },
                text: (
                  <>
                    <dl>
                      <dt>個室</dt>
                      <dd>3室</dd>
                    </dl>
                    <br />
                    <dl>
                      <dt>利用人数/室料</dt>
                      <dd>大人6名～8名：1室 6,000円</dd>
                      <dd>大人8名～16名：1室 8,000円</dd>
                      <dd>
                        <ul className="c_noteList u_mt5">
                          <li>
                            デリブッフェご利用のお席は90分、コース料理の場合は2時間の料金です。
                          </li>
                          <li>
                            13名様以上でご利用の際は、個室内でのテーブルが4卓に分かれます。
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </>
                ),
                title: <></>,
              },
            ]}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      <LPickup />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
